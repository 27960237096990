
import Vue from 'vue'
import { IHeaderOption, KwikMediumContainer, KwikPageToolbar } from 'kwik-vue'
import { IProductionOrdersRow } from '../types'
import { dispatchGetProductionOrdersBatches } from '../store/actions'

export default Vue.extend({
  name: 'FFProductionOrdersRows',
  components: { KwikMediumContainer, KwikPageToolbar },
  data () {
    return {
      options: {} as any,
      loading: true,
      productionOrdersRowItems: [] as IProductionOrdersRow[],
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Dettaglio ordini produzione', disabled: false }
      ]
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'ID',
          sortable: true,
          value: 'id',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Lotto',
          sortable: true,
          value: 'batch.code',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Quantità impegnata',
          sortable: true,
          value: 'quantity',
          hidden: false,
          align: 'start'
        }
      ] as IHeaderOption[]
      return defaultHeaders
    }
  },
  methods: {
    async loadPage (productionOrdersId: number) {
      this.loading = true
      const response = await dispatchGetProductionOrdersBatches(this.$store, productionOrdersId)
      this.productionOrdersRowItems = response.data
      this.loading = false
    }
  },
  async mounted () {
    const productionOrdersId = +this.$router.currentRoute.params.production_orders_id
    this.loadPage(productionOrdersId)
  }
})
